
// MUI
import { SvgIconProps } from '@mui/material';
import {
    Interests as InterestsIcon, Restaurant as NutritionIcon, AccessibilityNew as BodyConditionIcon,
    MedicationLiquid as PersonalCareIcon, Call as CommunicationIcon, HomeRounded as DwellingIcon,
    Train as TransportsIcon, Groups as InterpersonalRelationshipsIcon, Diversity3 as CommunityLifeIcon,
    School as EducationIcon, BusinessCenter as WorkIcon, SportsTennis as HobbiesIcon, Portrait as ResponsibilitiesIcon,
} from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------- LIFE HABIT ICON ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

interface ILifeHabitIconProps {
    lifeHabitId?: string;
    iconProps?: SvgIconProps;
}

export default function LifeHabitIcon(props: ILifeHabitIconProps) {

    const { lifeHabitId, iconProps } = props;

    switch (lifeHabitId) {

        // Nutrition
        case "1":
            return <NutritionIcon {...iconProps} />;

        // Body Condition
        case "2":
            return <BodyConditionIcon {...iconProps} />;

        // Personal Care
        case "3":
            return <PersonalCareIcon {...iconProps} />;

        // Communication
        case "4":
            return <CommunicationIcon {...iconProps} />;

        // Dwelling
        case "5":
            return <DwellingIcon {...iconProps} />;

        // Transports
        case "6":
            return <TransportsIcon {...iconProps} />;

        // Responsibilities
        case "7":
            return <ResponsibilitiesIcon {...iconProps} />;

        // Interpersonal Relationships
        case "8":
            return <InterpersonalRelationshipsIcon {...iconProps} />;

        // Community Life
        case "9":
            return <CommunityLifeIcon {...iconProps} />;

        // Education
        case "10":
            return <EducationIcon {...iconProps} />;

        // Work
        case "11":
            return <WorkIcon {...iconProps} />;

        // Hobbies
        case "12":
            return <HobbiesIcon {...iconProps} />;

        default:
            return <InterestsIcon {...iconProps} />;
    }
}
