import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';

// MUI
import { Drawer, Divider, List, ListItemButton, Icon } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';

// Services
import { useMediaQueries } from 'services/hooks';
import { blueMain } from 'services/constants';
import { getStoredUser, getPermissions } from 'services/storage';
import packageJSON from '../../../package.json';

// Components
import Logout from 'views/authenticated/logout';

// Icons
import LifeHabitIcon from 'icons/life-habit-icon';

// CSS
import styles from './Menu.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// ----------------------------------------------- MENU ----------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Menu = ({ open, onClose } : { open: boolean, onClose: VoidFunction }) => {

    const permissions = getPermissions();
    const storedUser = getStoredUser();
    const matchDashboard = useMatch('/app');

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { mediaMD } = useMediaQueries();
    const [openLogout, setOpenLogout] = useState(false);
    const [selected, setSelected] = useState<string | null>('dashboard');

    const iconProps : React.CSSProperties = {
        marginRight: 10,
        color: blueMain,
    };

    const onClickMenu = (menu: string) => {
        navigate('/app/' + menu);
        onClose();
    };

    const onClickLogout = () => {
        onClose();
        setOpenLogout(true);
    };

    useEffect(() => {
        let params = pathname.split('/');
        if (params && params.length >= 2) {
            setSelected(params[2]);
        }
    }, [pathname]);

    return (
        <>
            <Drawer
                anchor={mediaMD ? 'left' : 'top'}
                open={open}
                onClose={onClose}
            >
                <List style={{ minWidth: 280 }}>
                    {/* -------------------- DASHBOARD -------------------- */}
                    <ListItemButton
                        onClick={() => { navigate('/app'); onClose(); }}
                        selected={!!(matchDashboard)}
                    >
                        <Icon style={iconProps}>dashboard</Icon>
                        {t('dashboard')}
                    </ListItemButton>
                    {/* -------------------- INTERVENTIONS -------------------- */}
                    {permissions?.includes('interventions.viewAny') && (
                        <ListItemButton
                            onClick={() => onClickMenu('interventions')}
                            selected={selected === 'interventions'}
                        >
                            <Icon style={iconProps}>badge</Icon>
                            {t('interventions')}
                        </ListItemButton>
                    )}
                    {/* -------------------- BENEFICIARIES -------------------- */}
                    {permissions?.includes('beneficiaries.view') && (
                        <ListItemButton
                            onClick={() => onClickMenu('beneficiaries')}
                            selected={selected === 'beneficiaries'}
                        >
                            <Icon style={iconProps}>people</Icon>
                            {t('beneficiaries')}
                        </ListItemButton>
                    )}
                    {/* -------------------- QUESTIONNAIRES -------------------- */}
                    <ListItemButton
                        onClick={() => onClickMenu('meal-questionnaires')}
                        selected={selected === 'meal-questionnaires'}
                    >
                        <Icon style={iconProps}>question_answer</Icon>
                        {t('questionnaires')}
                    </ListItemButton>
                    {/* -------------------- LIFE HABITS -------------------- */}
                    <ListItemButton
                        onClick={() => onClickMenu('life-habits')}
                        selected={selected === 'life-habits'}
                    >
                        <LifeHabitIcon
                            iconProps={{style: iconProps}}
                        />
                        {t('life_habits')}
                    </ListItemButton>
                    {/* -------------------- STATISTICS -------------------- */}
                    <ListItemButton
                        onClick={() => onClickMenu('statistics')}
                        selected={selected === 'statistics'}
                    >
                        <Icon style={iconProps}>bar_chart</Icon>
                        {t('statistics')}
                    </ListItemButton>
                    {/* -------------------- MANAGEMENT -------------------- */}
                    {(storedUser?.role?.id && Number(storedUser.role.id) < 5) && (
                        <ListItemButton
                            onClick={() => onClickMenu('management')}
                            selected={selected === 'management'}
                        >
                            <Icon style={iconProps}>settings</Icon>
                            {t('management')}
                        </ListItemButton>
                    )}
                    {/* -------------------- LOGOUT -------------------- */}
                    <ListItemButton onClick={onClickLogout}>
                        <LogoutIcon style={iconProps} />
                        {t('logout')}
                    </ListItemButton>
                </List>
                <Divider />
                {/* -------------------- VERSION -------------------- */}
                <div className={styles.version}>
                    v{packageJSON.version}
                </div>
            </Drawer>
            {/* -------------------- LOGOUT DIALOG -------------------- */}
            <Logout
                open={openLogout}
                onClose={() => setOpenLogout(false)}
            />
        </>
    );
};

export default Menu;
