import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// MUI
import { Snackbar, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- COOKIES --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function Cookies() {

    const { t } = useTranslation();
    const cookieMessage = localStorage.getItem('cookies');
    const [openCookies, setOpenCookies] = useState(cookieMessage === 'READ' ? false : true);

    const onCloseCookies = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        localStorage.setItem('cookies', 'READ');
        setOpenCookies(false);
    };

    return (
        <>
            {!(cookieMessage === 'READ') && (
                <Snackbar
                    open={openCookies}
                    autoHideDuration={null}
                    message={t('not_use_cookies')}
                    action={(
                        <IconButton onClick={onCloseCookies}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    )}
                />
            )}
        </>
    );
}
