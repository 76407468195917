import { IUser } from './interfaces';

// --------------------------------------------------------------------------------- \\
// ------------------------------------ LOGIN -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const setLogin = (login: { token: string, user: IUser }, stayConnected?: boolean) => {

    let user : any = {...login.user};

    if (stayConnected) {

        localStorage.setItem('stayConnected', '1');
        localStorage.setItem('token', login.token);
        localStorage.setItem('permissions', JSON.stringify(user.permissions));

        delete user.permissions;
        localStorage.setItem('user', JSON.stringify(user));
    }
    else {
        
        localStorage.setItem('stayConnected', '0');
        sessionStorage.setItem('token', login.token);
        sessionStorage.setItem('permissions', JSON.stringify(user.permissions));

        delete user.permissions;
        sessionStorage.setItem('user', JSON.stringify(user));
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------------- TOKEN --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getStoredToken = () => {
    if (localStorage.getItem('stayConnected') === '1') {
        return localStorage.getItem('token');
    }
    return sessionStorage.getItem('token');
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- PERMISSIONS ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getPermissions = () => {

    let permissions : string | null = null;
    if (localStorage.getItem('stayConnected') === '1') {
        permissions = localStorage.getItem('permissions');
    }
    else {
        permissions = sessionStorage.getItem('permissions');
    }

    if (permissions) {
        return JSON.parse(permissions) as string[];
    }
    return null;
};

export const setPermissions = (permissions: string[]) => {
    if (localStorage.getItem('stayConnected') === '1') {
        localStorage.setItem('permissions', JSON.stringify(permissions));
    }
    else {
        sessionStorage.setItem('permissions', JSON.stringify(permissions));
    }
};

// --------------------------------------------------------------------------------- \\
// ------------------------------------ USER --------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getStoredUser = () => {

    let user : string | null = null;
    if (localStorage.getItem('stayConnected') === '1') {
        user = localStorage.getItem('user');
    }
    else {
        user = sessionStorage.getItem('user');
    }

    if (user) {
        return JSON.parse(user) as IUser;
    }
    return null;
};

export const setStoredUser = (user: IUser) => {
    if (localStorage.getItem('stayConnected') === '1') {
        localStorage.setItem('user', JSON.stringify(user));
    }
    else {
        sessionStorage.setItem('user', JSON.stringify(user));
    }
};

// --------------------------------------------------------------------------------- \\
// ----------------------------------- FILTERS ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const getStoredFilters = (key: string) => {
    let filters = sessionStorage.getItem(key);
    if (filters) {
        return JSON.parse(filters);
    }
    return null;
};

export const setStoredFilters = (key: string, value: any) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

// --------------------------------------------------------------------------------- \\
// ------------------------------------ CLEAR -------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const clearStorage = () => {

    let locale = localStorage.getItem('locale');
    let cookies = localStorage.getItem('cookies');
    let stayConnected = localStorage.getItem('stayConnected');

    sessionStorage.clear();
    localStorage.clear();

    localStorage.setItem('locale', locale || '');
    localStorage.setItem('cookies', cookies || '');
    localStorage.setItem('stayConnected', stayConnected || '');
};
