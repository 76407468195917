import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// MUI
import { Button, Grid } from '@mui/material';
import { WarningRounded as WarningIcon } from '@mui/icons-material';

// Services
import { magentaMain } from 'services/constants';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ PAGE NOT FOUND ------------------------------------------ \\
// ---------------------------------------------------------------------------------------------------- \\

export default function PageNotFound() {

    const { t } = useTranslation();

    return (
        <Grid
            padding={3}
            container
            flex={1}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <WarningIcon
                fontSize="large"
                htmlColor={magentaMain}
            />
            <span style={{ color: magentaMain }}>
                {t('page_not_found')}
            </span>
            <Button
                component={Link}
                to="/"
                color="inherit"
                sx={{ marginTop: 2 }}
            >
                {t('back_to_homepage')}
            </Button>
        </Grid>
    );
}
