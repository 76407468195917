import { useCallback, useEffect } from 'react';
import { setDocumentTitle } from './helpers';
import useMediaQuery from '@mui/material/useMediaQuery';

// --------------------------------------------------------------------------------- \\
// ------------------------------- DOCUMENT TITLE ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useDocumentTitle = (title: string) => {
    useEffect(() => {
        setDocumentTitle(title);
    }, [title]);
};

// --------------------------------------------------------------------------------- \\
// -------------------------------- MEDIA QUERIES ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useMediaQueries = () => {
    return {
        mediaXL: useMediaQuery('(min-width:1536px)'),
        mediaLG: useMediaQuery('(min-width:1200px)'),
        mediaMD: useMediaQuery('(min-width:900px)'),
        mediaSM: useMediaQuery('(min-width:600px)'),
        mediaXS: useMediaQuery('(min-width:0px)'),
        mediaDesktop: useMediaQuery('(pointer:fine)'),
    };
};

// --------------------------------------------------------------------------------- \\
// --------------------------------- SCROLL DATA ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const useScrollData = ({
    state,
    setState,
    dependency,
    containerId,
}: ({
    state: any,
    setState: React.Dispatch<React.SetStateAction<any>>,
    dependency: any,
    containerId?: string,
})) => {

    const onReachEnd = useCallback(() => {
        
        let layout = document.getElementById(containerId || 'ContentLayoutScroll');
        let scrollTop = layout?.scrollTop;
        let innerHeight = window.innerHeight;
        let scrollHeight = layout?.scrollHeight;

        if ((state.page * state.first < state.total) && (scrollTop && scrollHeight) && (scrollTop + innerHeight >= scrollHeight)) {
            layout?.removeEventListener('scroll', onReachEnd);
            setState((prevState: any) => ({ ...prevState, loading: true, page: prevState.page += 1 }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);

    useEffect(() => {
        
        let layout = document.getElementById(containerId || 'ContentLayoutScroll');
        layout?.addEventListener('scroll', onReachEnd);

        return () => {
            layout?.removeEventListener('scroll', onReachEnd);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependency]);
};
