import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import i18next from 'i18next';

// Notistack
import { SnackbarProvider } from 'notistack';

// MUI
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blueLight, blueMain, blueDark, yellowLight, yellowMain, yellowDark, magentaLight, magentaMain, magentaDark } from 'services/constants';

// Apollo Client
import { ApolloProvider } from '@apollo/client';
import client from 'services/apollo-config';

// CSS
import './index.css';

// App
import App from './app';

// Roboto Font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Dayjs
import 'dayjs/locale/fr';

// React International Phone CSS
import 'react-international-phone/style.css';

// Translation files
import translationFR from 'assets/translations/fr.translations.json';
import translationEN from 'assets/translations/en.translations.json';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- I18NEXT --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

i18next.use(initReactI18next).init({
    resources: {
        "fr": { translation: translationFR },
        "en": { translation: translationEN },
    },
    lng: 'fr',
    fallbackLng: {
        default: ['en'],
    },
});

/* * *
* MUI COMPONENTS
* - Enable to add customized colors to mui components
*/

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/ButtonGroup' {
    interface ButtonGroupPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}
declare module '@mui/material/Fab' {
    interface FabPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

declare module '@mui/material/Radio' {
    interface RadioPropsColorOverrides {
        blue: true;
        magenta: true;
        yellow: true;
    }
}

/* * *
* MUI PALETTE
* - Enable to add customized colors to mui palette
*/

declare module '@mui/material/styles' {
    interface Palette {
        blue: Palette['primary'];
        magenta: Palette['primary'];
        yellow: Palette['primary'];
    }
    interface PaletteOptions {
        blue: PaletteOptions['primary'];
        magenta: PaletteOptions['primary'];
        yellow: PaletteOptions['primary'];
    }
}

// ---------------------------------------------------------------------------------------------------- \\
// -------------------------------------------- MUI THEME --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const theme = createTheme({
    palette: {
        blue: {
            light: blueLight,
            main: blueMain,
            dark: blueDark,
            contrastText: '#fff',
        },
        magenta: {
            light: magentaLight,
            main: magentaMain,
            dark: magentaDark,
            contrastText: '#fff',
        },
        yellow: {
            light: yellowLight,
            main: yellowMain,
            dark: yellowDark,
            contrastText: '#fff',
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
                InputProps: {
                    style: {
                        backgroundColor: 'white',
                    },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
            },
            styleOverrides: {
                root: {
                    minWidth: 0,
                },
            },
        },
        MuiFab: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    zIndex: 0,
                    minWidth: 0,
                    minHeight: 0,
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                enterDelay: 400,
                leaveDelay: 200,
                placement: 'bottom',
            },
            styleOverrides: {
                tooltip: {
                    fontSize: 14,
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ApolloProvider client={client}>
        <SnackbarProvider maxSnack={3}>
            <ThemeProvider theme={theme}>
                <HelmetProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </HelmetProvider>
            </ThemeProvider>
        </SnackbarProvider>
    </ApolloProvider>
);
