import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

/* * *
* Lazy Imports
*/

const Report = lazy(() => import('../../../views/authenticated/reports/report'));

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------ REPORT ROUTES ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function ReportRoutes() {
    return (
        <Routes>
            <Route path=":reportId">
                <Route index element={<Report />} />
            </Route>
        </Routes>
    );
}
