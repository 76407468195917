import { IAgeRange } from './interfaces';

// --------------------------------------------------------------------------------- \\
// -------------------------------- ORIGIN LABEL ----------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const originLabel = window.origin.includes('repasetbienfaits') ? "Repas et Bienfaits" : "Compani'On Home";

// --------------------------------------------------------------------------------- \\
// --------------------------------- MUI COLORS ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const yellowLight = '#DBE572';
export const yellowMain = '#CFCF28';
export const yellowDark = '#B1BE3E';

export const magentaLight = '#E7679B';
export const magentaMain = '#E7307B';
export const magentaDark = '#BA2763';

export const blueLight = '#1C7CB6';
export const blueMain = '#005A86';
export const blueDark = '#0A3149';

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- MUI COLORS --------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const successMain = '#2e7d32';
export const warningMain = '#ed6c02';
export const errorMain = '#d32f2f';

// --------------------------------------------------------------------------------- \\
// ------------------------- FORBIDDEN KEYS NUMBER INPUT --------------------------- \\
// --------------------------------------------------------------------------------- \\

export const forbiddenKeysNumberInput = ['-', '+', 'e'];

// --------------------------------------------------------------------------------- \\
// ------------------------------ FORBIDDEN KEYS EXCEL ----------------------------- \\
// --------------------------------------------------------------------------------- \\

export const forbiddenKeysExcel = ['*', '?', ':', '/', '\'', '[', ']'];

// --------------------------------------------------------------------------------- \\
// --------------------------------- AGE RANGES ------------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const ageRanges : IAgeRange[] = [
    {
        ageMin: 50,
        ageMax: 60,
    },
    {
        ageMin: 61,
        ageMax: 70,
    },
    {
        ageMin: 71,
        ageMax: 80,
    },
    {
        ageMin: 81,
        ageMax: 90,
    },
    {
        ageMin: 91,
        ageMax: 100,
    },
];

// ---------------------------------------------------------------------------------------------------- \\
// ------------------------------------------- ALPHABET CHARS ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export const alphabetChars = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

// --------------------------------------------------------------------------------- \\
// ----------------------------------- LOCALES ------------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const locales = [
    {
        value: 'fr',
        label: 'french',
    },
    {
        value: 'en',
        label: 'english',
    },
];

// --------------------------------------------------------------------------------- \\
// --------------------------- INTERVENTION DURATIONS ------------------------------ \\
// --------------------------------------------------------------------------------- \\

export const interventionDurations = [
    {
        value: 5,
        label: '5min',
    },
    {
        value: 10,
        label: '10min',
    },
    {
        value: 15,
        label: '15min',
    },
    {
        value: 20,
        label: '20min',
    },
    {
        value: 25,
        label: '25min',
    },
    {
        value: 30,
        label: '30min',
    },
    {
        value: 45,
        label: '45min',
    },
    {
        value: 60,
        label: '1h',
    },
    {
        value: 75,
        label: '1h15'
    },
    {
        value: 90,
        label: '1h30',
    },
    {
        value: 105,
        label: '1h45',
    },
    {
        value: 120,
        label: '2h',
    },
    {
        value: 135,
        label: '2h15',
    },
    {
        value: 150,
        label: '2h30',
    },
    {
        value: 165,
        label: '2h45',
    },
    {
        value: 180,
        label: '3h',
    },
];

// --------------------------------------------------------------------------------- \\
// ------------------------------- RECURRING DAYS ---------------------------------- \\
// --------------------------------------------------------------------------------- \\

export const recurringDays = [
    {
        value: 0,
        label: 'every_sunday',
    },
    {
        value: 1,
        label: 'every_monday',
    },
    {
        value: 2,
        label: 'every_tuesday',
    },
    {
        value: 3,
        label: 'every_wednesday',
    },
    {
        value: 4,
        label: 'every_thursday',
    },
    {
        value: 5,
        label: 'every_friday',
    },
    {
        value: 6,
        label: 'every_saturday',
    },
];
