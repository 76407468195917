import React from 'react';

// MUI
import { Grid } from '@mui/material';

// CSS
import styles from './AppLayout.module.css';

// ---------------------------------------------------------------------------------------------------- \\
// --------------------------------------------- APP LAYOUT ------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function AppLayout({ children } : { children: React.ReactNode }) {
    return (
        <Grid container>
            <Grid
                item lg={1} xl={2}
            />
            <Grid item xs={12} lg={10} xl={8}>
                <div className={styles.container}>
                    {children}
                </div>
            </Grid>
        </Grid>
    );
}
