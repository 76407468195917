import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Notistack
import { useSnackbar } from 'notistack';

// Apollo
import { useMutation } from '@apollo/client';
import { LOGOUT } from './gql-logout';

// MUI
import { Dialog, DialogContent, DialogActions, Button, CircularProgress, Divider } from '@mui/material';

// Services
import { clearStorage } from 'services/storage';

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------------- LOGOUT ---------------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

const Logout = ({ open, onClose } : { open: boolean, onClose: VoidFunction }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [logout, { loading, error, data }] = useMutation(LOGOUT);

    const onLogout = () => {
        clearStorage();
        navigate('/');
    };

    useEffect(() => {
        if (data?.logout) {
            enqueueSnackbar(data.logout, { variant: 'success' });
            onLogout();
        }
        if (error) {
            onLogout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, error]);

    return (
        <Dialog
            open={open}
            onClose={loading ? undefined : onClose}
        >
            <DialogContent>
                {t('logout_confirm')}
            </DialogContent>
            <Divider />
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="blue"
                    disabled={loading}
                >
                    {t('no')}
                </Button>
                <Button
                    onClick={() => logout()}
                    color="inherit"
                    disabled={loading}
                    endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <></>}
                >
                    {t('yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Logout;
