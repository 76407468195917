import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

/* * *
* Lazy Imports
*/

const LifeHabits = lazy(() => import('../../../views/authenticated/life-habits'));
const LifeHabit = lazy(() => import('../../../views/authenticated/life-habits/life-habit'));
const LifeHabitObstacle = lazy(() => import('../../../views/authenticated/life-habits/life-habit/life-habit-obstacle'));

// ---------------------------------------------------------------------------------------------------- \\
// ---------------------------------------- LIFE HABIT ROUTES ----------------------------------------- \\
// ---------------------------------------------------------------------------------------------------- \\

export default function LifeHabitRoutes() {
    return (
        <Routes>
            <Route index element={<LifeHabits />} />
            <Route path=":lifeHabitId">
                <Route index element={<LifeHabit />} />
                <Route path="obstacles/:lifeHabitObstacleId" element={<LifeHabitObstacle />} />
            </Route>
        </Routes>
    );
}
